<template>
  <div>
    <div class="mid_boxs">
      <div class="mid_box_left_">
        <div class="mid_box_left_bt_top">
          <span>公司部门列表</span>
        </div>
        <div class="mid_box_left_bt" :style="{ height: elementHeight + 'px' }">
          <el-tree
            :data="companyData"
            :props="defaultProps"
            accordion
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
      </div>
      <div class="mid_box_right_">
        <div class="mid_box_left_bt_top" style="margin: 0 20px">
          <span>过程设置</span>
          <i class="post-icon-plus el-icon-plus" @click="addItem(true)"></i>
        </div>
        <el-table :data="tableData" style="width: 100%" :height="elementHeight">
          <el-table-column
            prop="processName"
            label="过程名称"
          ></el-table-column>
          <el-table-column prop="processNo" label="过程编号"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i
                id="i"
                class="el-icon-delete"
                @click="deleteItem(scope.row)"
              ></i>
              <i id="i" class="el-icon-edit" @click="editItem(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      :title="isAdd ? '新增过程' : '修改过程'"
      :visible.sync="dialogVisible"
      width="22%"
    >
      <el-form label-width="80px" ref="ruleForm">
        <el-form-item label="过程名称" prop="postName">
          <el-input v-model="processName"></el-input>
        </el-form-item>
        <el-form-item label="过程编号" prop="postName">
          <el-input v-model="processNo"></el-input>
        </el-form-item>
        <el-form-item label="所属公司">
          <el-select
            class="elselect"
            v-model="companyId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in companyList"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="reqData">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template> 

<script>
import { get, post, put, deleteMethod } from "../../api/http";

export default {
  data() {
    return {
      elementHeight: 0,
      companyData: [],
      defaultProps: {
        children: "companylist",
        label: "companyName",
      },
      treeItem: {},
      companyList: [],
      processName: "",
      processNo: "",
      dialogVisible: false,
      isAdd: true,
      companyId: 0,
      processItem: {},
      tableData: [],
    };
  },
  mounted() {
    this.getElementHeight();
    this.getCompanyData();
    this.companyList = this.$store.state.companyList;
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 10 + 70);
      });
    },
    getCompanyData() {
      get("/api/Company/GetTree").then((res) => {
        this.companyData = res.data;
      });
    },
    handleNodeClick(e) {
      this.treeItem = e;
      this.getTableList();
    },
    getTableList() {
      get("/api/Process?CompId=" + this.treeItem.id).then((res) => {
        this.tableData = res.data;
      });
    },
    addItem() {
      this.dialogVisible = true;
      this.isAdd = true;
      this.processName = "";
      this.processNo = "";
      this.companyId = this.treeItem.id;
    },
    editItem(row) {
      this.dialogVisible = true;
      this.isAdd = false;
      this.processName = row.processName;
      this.processNo = row.processNo;
      this.companyId = row.companyId;
      this.processItem = row;
    },
    reqData() {
      var data = {
        id: this.isAdd ? 0 : this.processItem.id,
        processNo: this.processNo,
        processName: this.processName,
        companyId: this.companyId,
        tenantId: this.$store.state.tenantId,
        isDelete: 0,
        saveTime: new Date().toISOString(),
        saveUserId: this.$store.state.userInfo.id + "",
      };
      var method = this.isAdd ? post : put;
      method("/api/Process", data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.isAdd ? "新增成功" : "修改成功",
            });
            this.dialogVisible = false;
            this.getTableList();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? "新增失败" : "修改失败");
        });
    },
    deleteItem(e) {
      console.log(e)
      this.$confirm("此操作将永久删过程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMethod("/api/Process?Id=" + e.id)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.getTableList();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch(() => {
              this.$message.error("系统异常，请稍后再试");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped lang="less">
@import "../../assets/style/Post.css";
/deep/ .temp-table {
  padding-left: 20px;
  padding-top: 20px;
  .el-table__header {
    tr:nth-of-type(1) > th:nth-of-type(2) .cell {
      //部门
      color: #000000b9 !important;
    }
    tr:nth-of-type(2) > th:nth-of-type(1) .cell {
      //过程
      color: #000000b9 !important;
    }
  }
  .el-table__body {
    .el-table__cell {
      background-color: white !important;
      border: 1px solid #d3d3d3;
      border-left: none;
    }
    .el-table__row {
      td:nth-of-type(1) {
        background-color: #edf0f7 !important;
      }
    }
  }
}
/deep/.el-tree .is-current > .el-tree-node__content {
  background-color: #eef7ff;
  color: #409eff;
}
</style>
